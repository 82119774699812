import React from "react";

export default function DataScienceImg(props) {
  const theme = props.theme;
  return (
    <svg
      id="fa973782-3a18-4630-a762-87130b613ac3"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 1120 829.80067"
    >
      <title></title>
      <ellipse
        cx="975.57906"
        cy="811.05902"
        rx="144.42094"
        ry="18.74165"
        fill={theme.imageDark}
      />
      <rect y="27.5" width="436" height="272" fill={theme.imageDark} />
      <rect
        x="27.5"
        y="1"
        width="436"
        height="272"
        fill="none"
        stroke="#3F3D56"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M352.468,237.56768A94,94,0,0,0,286,77.09967v94Z"
        transform="translate(-40 -34.59967)"
        fill={theme.text}
      />
      <path
        d="M286,77.09967a94.01123,94.01123,0,0,0-44.968,176.56414L286,171.09967Z"
        transform="translate(-40 -34.59967)"
        fill={theme.accentColor}
      />
      <path
        d="M352.468,237.56768,286,171.09967l-44.968,82.56414a94.04908,94.04908,0,0,0,111.436-16.09613Z"
        transform="translate(-40 -34.59967)"
        fill="#FE9800"
      />
      <rect x="44" y="216.5" width="18" height="18" fill={theme.accentColor} />
      <rect x="70" y="216.5" width="18" height="18" fill={theme.text} />
      <rect x="96" y="216.5" width="18" height="18" fill="#FE9800" />
      <rect x="355" y="220.5" width="74" height="3" fill={theme.text} />
      <rect x="355" y="229.5" width="74" height="3" fill={theme.text} />
      <rect x="355" y="238.5" width="74" height="3" fill={theme.text} />
      <rect x="137" y="406.5" width="248" height="272" fill={theme.imageDark} />
      <rect
        x="162"
        y="380.5"
        width="248"
        height="272"
        fill="none"
        stroke="#3F3D56"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <rect
        x="175"
        y="621.81429"
        width="13.37143"
        height="13.37143"
        fill={theme.accentColor}
      />
      <rect
        x="194.31429"
        y="621.81429"
        width="13.37143"
        height="13.37143"
        fill={theme.text}
      />
      <rect
        x="213.62857"
        y="621.81429"
        width="13.37143"
        height="13.37143"
        fill="#FE9800"
      />
      <rect
        x="325"
        y="627.62162"
        width="52"
        height="2.10811"
        fill={theme.text}
      />
      <rect
        x="325"
        y="633.94595"
        width="52"
        height="2.10811"
        fill={theme.text}
      />
      <rect
        x="325"
        y="640.27027"
        width="52"
        height="2.10811"
        fill={theme.text}
      />
      <path
        d="M326,463.09967v22a66,66,0,0,1,46.66907,112.66906l15.55633,15.55634A88,88,0,0,0,326,463.09967Z"
        transform="translate(-40 -34.59967)"
        fill={theme.text}
      />
      <path
        d="M388.2254,613.32507l-15.55633-15.55634a65.98344,65.98344,0,0,1-86.6,5.87165L272.7533,621.16113a87.99775,87.99775,0,0,0,115.4721-7.83606Z"
        transform="translate(-40 -34.59967)"
        fill="#FE9800"
      />
      <path
        d="M260,551.09967a66,66,0,0,1,66-66v-22a87.99914,87.99914,0,0,0-53.2467,158.06146l13.31579-17.52075A65.885,65.885,0,0,1,260,551.09967Z"
        transform="translate(-40 -34.59967)"
        fill={theme.accentColor}
      />
      <path
        d="M971.62443,175.593s-4.08028,30.31061,6.41186,33.808,7.57765,30.31062,7.57765,30.31062l-31.47641,23.89875-27.979,2.33159-6.99476-36.13958L923.244,220.476s15.73821-18.65268,4.66318-37.88826S971.62443,175.593,971.62443,175.593Z"
        transform="translate(-40 -34.59967)"
        fill={theme.skinColor}
      />
      <path
        d="M971.62443,175.593s-4.08028,30.31061,6.41186,33.808,7.57765,30.31062,7.57765,30.31062l-31.47641,23.89875-27.979,2.33159-6.99476-36.13958L923.244,220.476s15.73821-18.65268,4.66318-37.88826S971.62443,175.593,971.62443,175.593Z"
        transform="translate(-40 -34.59967)"
        opacity="0.1"
      />
      <path
        d="M942.47961,411.666,905.75713,497.9347s-28.56192-16.3211-45.46592-14.57241-37.88827-18.65268-37.88827-18.65268l20.98427-101.424,39.05406-107.83584,54.20937,34.39089Z"
        transform="translate(-40 -34.59967)"
        fill={theme.accentColor}
      />
      <path
        d="M942.47961,411.666,905.75713,497.9347s-28.56192-16.3211-45.46592-14.57241-37.88827-18.65268-37.88827-18.65268l20.98427-101.424,39.05406-107.83584,54.20937,34.39089Z"
        transform="translate(-40 -34.59967)"
        opacity="0.1"
      />
      <polygon
        points="960.186 743.708 960.186 771.687 933.956 771.687 938.036 739.045 960.186 743.708"
        fill={theme.accentColor}
      />
      <polygon
        points="1028.385 695.328 1047.621 706.986 1033.631 739.045 1019.059 737.296 1005.069 717.478 1028.385 695.328"
        fill={theme.accentColor}
      />
      <path
        d="M1005.43242,403.50548l34.39089,81.02261s-12.24083,89.766-11.65793,97.3437,1.16579,10.49214-1.74869,16.904-4.08027,10.49213-2.33158,18.06979,2.33158,10.49213,1.16579,13.40661-9.32634,122.99115-13.40662,131.1517,2.33159,21.56717-1.74869,22.733-34.39089-1.74869-36.13958-8.74344,5.829-23.89876,1.74869-29.14483.5829-194.10451-5.24607-206.92823-69.94757-24.48165-69.94757-24.48165-5.829-37.30537-2.33158-43.13433S906.34,429.15293,906.34,429.15293Z"
        transform="translate(-40 -34.59967)"
        fill={theme.imageClothes}
      />
      <path
        d="M971.62443,801.62374s16.3211-19.23558,30.31061,0c0,0,1.16579,22.733,11.075,27.39614s5.24607,23.31585-8.74344,23.89875-32.05931,2.91448-32.6422-4.66317,2.33158-12.24083-2.91448-14.57241,0-15.7382,0-15.7382Z"
        transform="translate(-40 -34.59967)"
        fill={theme.imageClothes}
      />
      <path
        d="M973.37312,522.99925s-.5829,41.96854-4.66317,47.79751-6.41187,9.32634-4.08028,15.7382,5.829,18.06979,4.08028,20.40138,1.74868,14.57241,1.74868,14.57241,44.883,21.56717,65.8673,66.45019c0,0,21.56717,35.55668,27.979,36.13958s13.98951-4.08028,10.49214,9.32634-16.904,30.89351-26.81324,29.14482S1030.497,743.917,1030.497,743.917s-77.52523-78.691-85.68578-81.02261-31.47641-20.98427-35.55668-32.6422-12.82372-109.00163-8.74345-115.41349,19.23559-15.15531,19.23559-15.15531Z"
        transform="translate(-40 -34.59967)"
        fill={theme.imageClothes}
      />
      <path
        d="M1074.2142,727.5959s36.13958,1.16579,36.72247,9.90924-15.1553,60.62123-12.82372,72.86205-18.06979,40.21986-31.4764,34.39089-2.91449-36.13958-2.91449-36.13958-13.40661-41.38564-11.075-41.96854,25.64744-7.57765,24.48165-14.57241S1074.2142,727.5959,1074.2142,727.5959Z"
        transform="translate(-40 -34.59967)"
        fill={theme.imageClothes}
      />
      <circle
        cx="904.51974"
        cy="136.03869"
        r="36.13958"
        fill={theme.skinColor}
      />
      <path
        d="M980.95077,226.305s-19.81848,20.98427-34.39089,17.48689-22.15006-28.56192-22.15006-28.56192l-26.23034-4.66318s-18.65269,82.7713-18.65269,90.93185,18.65269,96.17791,18.65269,111.91611-15.15531,25.06455,8.16055,24.48165,12.24082-8.16055,46.04882-8.74344,39.05406,6.99475,46.04881-5.24607a97.52308,97.52308,0,0,1,17.4869-21.56717l-12.24083-43.13434L1001.935,237.38s-14.94433-29.14482-21.75313-26.81324C980.18191,210.56676,983.86525,223.39049,980.95077,226.305Z"
        transform="translate(-40 -34.59967)"
        fill={theme.accentColor}
      />
      <path
        d="M973.7711,205.41135s31.07842,3.40672,39.82187,9.81859,33.2251,11.075,32.6422,15.7382-28.56192,71.11336-28.56192,71.11336-12.82373,52.46068-1.74869,64.70151,52.46067,145.72411,45.46592,156.79914-29.14482-33.808-29.14482-33.808-29.72772-96.17791-46.04882-112.499S968.71,272.93668,968.71,268.85641,973.7711,205.41135,973.7711,205.41135Z"
        transform="translate(-40 -34.59967)"
        fill={theme.accentColor}
      />
      <path
        d="M928.31275,212.17213S890.01893,197.743,876.61231,203.572s-21.56717,6.99475-21.56717,6.99475L850.382,267.69061s-1.74869,82.1884-16.904,102.00688-25.06454,102.58977-22.733,110.75032-4.08027,22.15007,5.829,18.65269,37.30537-83.93709,37.30537-83.93709,0-46.04882,16.3211-58.87254,43.13433-65.86729,44.30013-78.691,12.24082-48.3804,12.24082-48.3804Z"
        transform="translate(-40 -34.59967)"
        fill={theme.accentColor}
      />
      <path
        d="M1059.64179,395.92783S1042.1549,428.57,1039.82331,434.399s-18.65269,45.46593-41.96854,36.72248,22.733-51.29489,22.733-51.29489l10.49213-35.55668S1058.476,378.44094,1059.64179,395.92783Z"
        transform="translate(-40 -34.59967)"
        fill={theme.skinColor}
      />
      <path
        d="M860.87411,210.56676h-5.829s-5.829-4.66317-12.82372-1.74869-23.31586-1.16579-23.31586.5829-18.06979-5.24607-25.64744-1.74869-34.97379,4.66317-34.97379,4.66317-11.075,27.979-3.49737,33.2251,18.65268,1.16579,23.89875,5.24607,15.15531-.5829,18.65268,4.66317,16.3211,14.57241,19.23559,8.74345-2.91449-11.075,9.32634-4.66318,31.47641,6.41187,31.47641,6.41187Z"
        transform="translate(-40 -34.59967)"
        fill={theme.accentColor}
      />
      <path
        d="M947.77766,143.93681c-4.89044-1.13-9.96711.1222-14.92033.93394s-10.41184,1.07935-14.60177-1.6843c-3.0943-2.041-4.99673-5.41753-6.78315-8.66544a9.97336,9.97336,0,0,1-1.45909-3.82348c-.21032-2.40206,1.31768-4.59363,2.94029-6.37723,7.537-8.28481,18.55107-12.81929,29.65825-14.25967,9.13047-1.184,18.64965-.3911,27.08125,3.307s15.67956,10.47034,18.99451,19.05978a54.12707,54.12707,0,0,1,2.83686,13.61089,40.65711,40.65711,0,0,1,.19122,10.30794,44.18819,44.18819,0,0,1-1.99761,7.46792L984.22692,180.395a10.92419,10.92419,0,0,1-2.15919,4.27407,3.73555,3.73555,0,0,1-4.37439.95837,13.61344,13.61344,0,0,0,.19684-5.50046,4.64127,4.64127,0,0,0-3.66213-3.69318c-2.024-.21479-3.753,1.35417-5.673,2.02989a6.979,6.979,0,0,1-8.811-5.24475c-.30309-1.89562.20582-3.92494-.51269-5.7051-.84183-2.08568-3.07419-3.17852-4.7513-4.67723a10.93629,10.93629,0,0,1-3.00075-11.3656c.449-1.37991,1.717-2.00722,1.46331-3.59244C952.60981,145.79888,949.54364,144.34487,947.77766,143.93681Z"
        transform="translate(-40 -34.59967)"
        fill={theme.brown}
      />
      <rect x="507" y="236.5" width="248" height="272" fill={theme.imageDark} />
      <rect
        x="532"
        y="210.5"
        width="248"
        height="272"
        fill="none"
        stroke="#3F3D56"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <polyline
        points="556 227.5 556 435.5 757 435.5"
        fill="none"
        stroke="#3F3D56"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <rect x="567" y="362.5" width="45" height="72" fill="#FE9800" />
      <rect x="632" y="313.5" width="45" height="121" fill={theme.text} />
      <rect
        x="697"
        y="256.5"
        width="45"
        height="178"
        fill={theme.accentColor}
      />
      <rect
        x="553"
        y="451.81429"
        width="13.37143"
        height="13.37143"
        fill={theme.accentColor}
      />
      <rect
        x="572.31429"
        y="451.81429"
        width="13.37143"
        height="13.37143"
        fill={theme.text}
      />
      <rect
        x="591.62857"
        y="451.81429"
        width="13.37143"
        height="13.37143"
        fill="#FE9800"
      />
      <rect
        x="703"
        y="457.62162"
        width="52"
        height="2.10811"
        fill={theme.text}
      />
      <rect
        x="703"
        y="463.94595"
        width="52"
        height="2.10811"
        fill={theme.text}
      />
      <rect
        x="703"
        y="470.27027"
        width="52"
        height="2.10811"
        fill={theme.text}
      />
      <path
        d="M764.3076,212.28192c10.62848.46211,18.52575,10.10069,17.376,20.67691-1.04159,9.58175-4.884,21.50465-16.98745,26.08434-21.56716,8.16055-20.40137-37.88827-16.3211-39.637C751.09528,218.24042,755.36985,211.89333,764.3076,212.28192Z"
        transform="translate(-40 -34.59967)"
        fill={theme.skinColor}
      />
      <path
        d="M1039.82331,227.854l6.41186,3.11414s13.98951,28.56192,14.57241,32.6422,9.90924,19.23558,6.41186,23.31586-8.74344,14.57241-5.24607,18.65268,11.65793,20.40138,11.65793,26.23034-8.74344,21.56717-2.91448,23.31586,4.08028,24.48165,2.91448,26.23034-16.3211,30.31061-16.3211,30.31061-2.91448-32.6422-31.4764-19.23558c0,0-2.33159-16.3211,0-17.48689s8.16055-8.16055,3.49737-9.32635-11.075-15.1553-9.90924-16.904,4.08028-4.08028,1.1658-5.829-5.24607-11.65792-4.66317-13.40661-6.41186-9.90924-6.41186-9.90924Z"
        transform="translate(-40 -34.59967)"
        fill={theme.accentColor}
      />
    </svg>
  );
}
