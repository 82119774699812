import React from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ProjectCard from "../../components/ProjectCard/ProjectCard";
import { Fade } from "react-reveal";
import { projectsHeader, projects, socialMediaLinks } from "../../portfolio.js";
import "./Projects.css";
import ProjectsImg from "./ProjectsImg";
import { style } from "glamor";

function Projects(props) {
  const theme = props.theme;

  const styles = style({
    backgroundColor: `${theme.accentColor}`,
    ":hover": {
      boxShadow: `0 5px 15px ${theme.accentColor}`,
    },
  });

  return (
    <div className="projects-main">
      <Header theme={theme} setTheme={props.setTheme} language={props.language} setLanguage={props.setLanguage} />
      <div className="basic-projects">
        <Fade bottom duration={2000} distance="40px">
          <div className="projects-heading-div">
            <div className="projects-heading-img-div">
              <ProjectsImg theme={theme} />
            </div>
            <div className="projects-heading-text-div">
              <h1
                className="projects-heading-text"
                style={{ color: theme.text }}
              >
                {props.language === 'en' ? projectsHeader.title : projectsHeader.title_fr}
              </h1>
              <p
                className="projects-header-detail-text subTitle"
                style={{ color: theme.secondaryText }}
              >
                {props.language === 'en' ? projectsHeader["description"] : projectsHeader["description_fr"]}
              </p>
            </div>
          </div>
        </Fade>
      </div>
      <div className="repo-cards-div-main">
        {projects.data.map((repo) => {
          return <ProjectCard repo={repo} theme={theme} language={props.language} />;
        })}
      </div>
      <br/><br/><br/>
      <a
        {...styles}
        className="general-btn"
        href={socialMediaLinks.github}
        target="_blank"
        rel="noopener noreferrer"
      >
        {props.language === 'en' ? 'See more (Github)' : 'Voir plus (Github)'}
      </a>
      <br/><br/><br/><br/>
      <Footer theme={props.theme} onToggle={props.onToggle}  language={props.language} />
    </div>
  );
}

export default Projects;
